const buttonTrackingIds = {
  ACCORDION: "Accordion",
  ACCOUNT_NUMBER__INFO_BOX__CLOSE: "Account number - Info box - close",
  ACCOUNT_NUMBER__INFO_BOX__OPEN: "Account number - Info box - open",
  ALREADY_ACTIONED: "Already actioned",
  ALREADY_ACTIONED__HIDE: "Already actioned - hide cta",
  ARTICLE_START: "Key information to move product",
  ARTICLES_CONTINUE: "Continue",
  ASK_FOR_HELP: "Ask for help",
  BOOK_APPOINTMENT: "Book appointment",
  BOOK_APPOINTMENT_CONTENT_BOX: "Book appointment - Content Box",
  CALCULATE_CURRENT_SITUATION: "Calculate current situation",
  CHANGE_DATA__ACTION_BAR: "Change Data - Action Bar",
  CHANGE_DATA__NO_RESPONSE: "Change Data - No Response",
  CHANGE_DATA__SPOKE_TO_CLIENT: "Change Data - Spoke To Client",
  CLOSE_MENU: "Close menu",
  COOKIE_POLICY__ACCEPT_ALL: "Cookie policy - accept all",
  COOKIE_POLICY__SAVE: "Cookie policy - save",
  COOKIE_POLICY__TOGGLE_FUNCTIONAL: "Cookie policy - toggle (functional)",
  COOKIE_POPOVER__ACCEPT: "Cookie policy - accept", // poorly named, can't change now
  COOKIE_POPOVER__BACK: "Cookie popover - back",
  COOKIE_POPOVER__EXPANDO__ESSENTIAL: "Cookie policy - expando (essential)",
  COOKIE_POPOVER__EXPANDO__FUNCTIONAL: "Cookie policy - expando (functional)",
  COOKIE_POPOVER__EXTERNAL_LINK: "Cookie policy - external link",
  COOKIE_POPOVER__FIND_OUT_MORE: "Cookie popover - find out more",
  COOKIE_POPOVER__MANAGE_COOKIES: "Cookie policy - manage cookies",
  COOKIE_POPOVER__SAVE: "Cookie popover - save",
  COOKIE_POPOVER__TOGGLE_FUNCTIONAL: "Cookie popover - toggle (functional)",
  COPY_MY_ACCOUNT_NUMBER: "Copy my account number",
  CURRENT_SITUATION: "Current situation",
  CURRENT_SITUATION__ALL_GOOD: "Current situation / all good",
  CURRENT_SITUATION__REQUEST_CALLBACK: "Current situation / request callback",
  CURRENT_SITUATION__SECONDARY_CTA: "Current Situation - Secondary CTA",
  CURRENT_SITUATION__ZERO_MORTGAGES_CTA: "Current Situation - Zero Mortgages CTA",
  DO_NOTHING: "Do nothing",
  EMPLOYEE_EDIT_BACK_TO_LIST: "Employee Edit - Back To List",
  EMPLOYEE_EDIT_REMOVE_TEAMMATE: "Employee Edit - Remove Teammate",
  EMPLOYEE_EDIT_SAVE: "Employee Edit - Save",
  EMPTY_HAVE_YOU_ACTIONED_LIST: "Have You Actioned - Empty List - Back to Leads",
  EXPLORE_PROCESS_PORTAL_NAV: "Explore process - Portal nav",
  EXPLORE_PROCESS_PAUSED_BOX: "Explore process - Paused box",
  EXPLORE_PROCESS_SWITCHING_BOX: "Explore process - Switching box",
  EXPLORE_PROCESS_OPTIONS_ACTIONED_BOX: "Explore process - Options actioned box",
  EXPLORE_PROCESS_OPTIONS_PAUSED_BOX: "Explore process - Options paused box",
  START_YOUR_REVIEW: "Start your review",
  CONTINUE_YOUR_REVIEW: "Continue your review",
  FILE_DETAIL__BACK_TO_LIST: "File detail - back to list",
  FILE_DETAIL__DELETE_FILE: "File detail - delete file",
  FILE_DETAIL__DOWNLOAD_FILE: "File detail - download file",
  FIND_ADVISOR: "Find advisor",
  FIND_ADVISOR_CONTENT_BOX: "Find advisor - Content Box",
  FROM_ARTICLE_PRIMARY: "From article primary",
  FROM_ARTICLE_SECONDARY: "From article secondary",
  FROM_LOGO_TO_HOME: "From header logo to home route",
  CONTACT_SUPPORT: "Contact support",
  GET_HELP: "Get help",
  HAVE_YOU_ACTIONED__CONTACTED__FALSE: "Have You Actioned - Contacted - False",
  HAVE_YOU_ACTIONED__CONTACTED__TRUE: "Have You Actioned - Contacted - True",
  HEADER__LOGO: "Header - logo",
  HELPSCOUT_BEACON_OPEN: "Helpscout Beacon Opened",
  INFO_CARD_STACK_MODAL_OPENED: "Info card modal opened",
  INFO_CARD_STACK_MODAL_NEXT: "Next button clicked info card modal",
  INFO_CARD_STACK_MODAL_PREV: "Prev button clicked info card modal",
  KBA__INFO_BOX__CLOSE: "KBA - Info box - close",
  KBA__INFO_BOX__OPEN: "KBA - Info box - open",
  LEARN_ABOUT_SWITCHING: "Learn about switching",
  LENDER_GET_HELP_URL_BUTTON: "Get help - Open URL",
  LENDER_WITH_SWITCH_SUPPORT_URL_BUTTON: "Contact support - Open URL",
  LENDER_INTERNAL_ADVICE_URL_BUTTON: "Internal Advice - Open Internal URL",
  LIFECYCLE__BACK_FROM_MODULE: "Lifecycle - Back From Module",
  LIFECYCLE__BACK_FROM_MORTGAGES_HOME: "Lifecycle - Back From Mortgages Home",
  LIFECYCLE__INTEREST_RATES__CONFIRM_ALL_GOOD:
    "Lifecycle - Interest Rates - Confirm All Good",
  LIFECYCLE__INTEREST_RATES__INFO_CARD: "Lifecycle - Interest Rates - Info Card",
  LIFECYCLE__INTEREST_RATES__REQUEST_CALL: "Lifecycle - Interest Rates - Request Call",
  LIFECYCLE__PRODUCT_RATES__APRC: "Lifecycle - Product Rates - APRC",
  LIFECYCLE__PRODUCT_RATES__CONFIRM_ALL_GOOD:
    "Lifecycle - Product Rates - Confirm All Good",
  LIFECYCLE__PRODUCT_RATES__EARLY_REPAYMENT_CHARGES:
    "Lifecycle - Product Rates - Early Repayment Charges",
  LIFECYCLE__PRODUCT_RATES__INFO_CARD: "Lifecycle - Product Rates - Info Card",
  LIFECYCLE__PRODUCT_RATES__LEGAL_FEES: "Lifecycle - Product Rates - Legal Fees",
  LIFECYCLE__PRODUCT_RATES__LENDER_ARRANGEMENT_FEES:
    "Lifecycle - Product Rates - Lender Arrangement Fees",
  LIFECYCLE__PRODUCT_RATES__MONTHLY_COST_OF_SWITCHING:
    "Lifecycle - Product Rates - Monthly Cost of Switching",
  LIFECYCLE__PRODUCT_RATES__MONTHLY_COST_TO_SWITCH__CLOSE:
    "Product Rates - Monthly Cost To Switch Accordion - Close",
  LIFECYCLE__PRODUCT_RATES__MONTHLY_COST_TO_SWITCH__OPEN:
    "Product Rates - Monthly Cost To Switch Accordion - Open",
  LIFECYCLE__PRODUCT_RATES__REQUEST_CALL: "Lifecycle - Product Rates - Request Call",
  LIFECYCLE__PRODUCT_RATES__SELECT_INITIAL_TERM:
    "Lifecycle - Product Rates - Select Initial Term",
  LIFECYCLE__PROPERTY_HEALTH_CARD_CTA: "Property health card CTA",
  LIFECYCLE__PROPERTY_VALUE__BASED_ON: "Lifecycle - Property Value - Based On",
  LIFECYCLE__PROPERTY_VALUE__CONFIRM_ALL_GOOD:
    "Lifecycle - Property Value - Confirm All Good",
  LIFECYCLE__PROPERTY_VALUE__LEARN_ABOUT_LTV:
    "Lifecycle - Property Value - Learn About LTV Link",
  LIFECYCLE__PROPERTY_VALUE__REQUEST_CALL: "Lifecycle - Property Value - Request Call",
  LIFECYCLE__PROPERTY_VALUE__SHOW_GRAPH:
    "Lifecycle - Property Value - Calculate property value",
  LIFECYCLE__PROPERTY_VALUE__VALUATIONS_EXPLAINED:
    "Lifecycle - Property Value - Valuations Explained",
  LIFECYCLE_PROSPECT__NO_ANSWER: "Lifecycle Prospect - No Answer",
  LIFECYCLE_PROSPECT_DIFFERENT_CASE__NO: "Lifecycle Prospect Different Case - No",
  LIFECYCLE_PROSPECT_DIFFERENT_CASE__YES: "Lifecycle Prospect Different Case - Yes",
  LOAD_APPROVAL__CHANGE_DATA: "Load Approval - Change Data",
  LOAD_APPROVAL__DISCARD_MORTGAGE_CONFIRM: "Load Approval - Discard Mortage Confirm",
  LOAD_APPROVAL__DISCARD_RECORD_CONFIRM: "Load Approval - Discard Record Confirm",
  LOAD_APPROVAL__KEEP_MORTGAGE_CONFIRM: "Load Approval - Keep Mortage Confirm",
  LOAD_APPROVAL__MORTGAGE_ACCORDION_CLOSE: "Load Approval - Close Mortgage Accordion",
  LOAD_APPROVAL__MORTGAGE_ACCORDION_OPEN: "Load Approval - Open Mortgage Accordion",
  LOAD_APPROVAL__PROPERTY_ACCORDION_CLOSE: "Load Approval - Close Property Accordion",
  LOAD_APPROVAL__PROPERTY_ACCORDION_OPEN: "Load Approval - Open Property Accordion",
  LOAD_APPROVAL__RESOLVE_REVIEW_STATE: "Load Approval - Resolve Review State",
  LOAD_APPROVAL__SUBMIT_CHANGES: "Load Approval - Submit Changes",
  LOAD_APPROVAL__VIEW_IN_SYSTEM_NEW_TAB: "Load Approval - View In System Tab",
  LENDER_EXTERNAL_ADVICE_URL_BUTTON: "External Advice - Open External URL",
  MENU__ACTIONED_MORTGAGE: "Menu - actioned mortgage",
  MENU__CHANGE_PASSWORD: "Menu - change password",
  MENU__CONTACT_PREFERENCES: "Menu - contact preferences",
  MENU__EXPLORE_SWITCHING: "Menu - explore switching",
  MENU__HOME: "Menu - home",
  MENU__LOG_IN: "Menu - log in",
  MENU__LOG_OUT: "Menu - log out",
  MENU__MANAGE_NOTIFICATION: "Menu - manage notification",
  MENU__MORTGAGE_HEALTH: "Menu - Mortgage health",
  MENU__MORTGAGES_HOME: "Menu - Mortgages home",
  MENU__OVERVIEW: "Menu - overview",
  MENU__REQUEST_CALL: "Menu - request call",
  MENU__RESET_PASSWORD: "Menu - reset password",
  MENU__YOUR_MORTGAGE_PAYMENT: "Menu - your mortgage payment",
  MONTHLY_PAYMENT__INFO_BOX__CLOSE: "Monthly payment - Info box - close",
  MONTHLY_PAYMENT__INFO_BOX__OPEN: "Monthly payment - Info box - open",
  MORE_OPTIONS: "More options",
  MORE_OPTIONS_CONTENT_BOX: "More options - Content Box",
  MORTGAGE_HOME_ALERT_CHECK: "Mortgage home alert check",
  MORTGAGE_INTRO_PAGE_CTA: "Mortgage intro page CTA",
  MORTGAGE_OVERVIEW_MORE_INFO: "Mortgage information",
  OPEN_MENU: "Open menu",
  ONBOARDING__HIDE_MODAL: "Onboarding - hide modal",
  ONBOARDING__HIDE_COMPLETE_ONBOARDING_MODAL: "Complete Onboarding - hide modal",
  ONBOARDING__UPLOAD_IN_MODAL: "Onboarding - upload in modal",
  ONBOARDING__WELCOME_MODAL_NEXT: "Onboarding - welcome modal next",
  ONBOARDING__FIRST_UPLOAD_MODAL_BACK_TO_CHECKLIST:
    "Onboarding - first upload modal - back to checklist",
  ONBOARDING__FIRST_UPLOAD_MODAL_UPLOAD_ANOTHER_FILE:
    "Onboarding - first upload modal - upload another file",
  POLICIES__ACCESSIBILITY_POLICY: "Policies - accessibility policy",
  POLICIES__COOKIE_POLICY: "Policies - cookie policy",
  POLICIES__DPN: "Policies - dpn policy",
  POLICIES__PRIVACY_POLICY: "Policies - privacy policy",
  POLICIES__TERMS_OF_BUSINESS_POLICY: "Policies - terms of business policy",
  POLICIES_TAB: "Policies tab",
  PORTAL_NAV_BUTTON: "Portal nav button",
  QUICK_SELECT_CASE_STARTED: "Quick Select - Open Case Started Modal",
  QUICK_SELECT_NOT_INTERESTED: "Quick Select - Open Not Interested Modal",
  QUICK_SELECT_RESCHEDULE_MEETING_TIME:
    "Quick Select - Reschedule via Select Meeting Time Modal",
  QUICK_SELECT_SELECT_MEETING_TIME: "Quick Select - Open Select Meeting Time Modal",
  SEE_RATES: "See rates",
  SET_REMINDER: "Set reminder",
  SHOW_ACCOUNT_NUMBER: "Show account number",
  SPLASH__CONFIRM_DIRECT_CALL_REQUEST: "Splash - confirm direct call request",
  SPLASH__CONFIRM_OPT_OUT: "Splash - confirm opt out",
  SPLASH__CONFIRM_SNOOZE: "Splash - confirm snooze",
  SPLASH__DECLINE_OPT_OUT: "Splash - decline opt out",
  SPLASH__EMAIL_SET_REMINDER: "Splash - email set reminder",
  SPLASH__REQUEST_CALL: "Splash - request call",
  SPLASH__THANKS_NEXT: "Thanks - Next button",
  START_SWITCH: "Start switch",
  SUPPORT_CTA__GET_SUPPORT: "Support Cta - support link",
  SWITCH_NOW: "Switch now",
  SWITCH_NOW_CONTENT_BOX: "Switch now - Content Box",
  TOPICS_INFO_BOX_OPEN_MODAL: "Topics Info Box - Open Modal",
  TOPICS_INFO_BOX_REMOVE: "Topics Info Box - Remove",
  TOPICS_MODAL_SKIP: "Topics Modal - Skip",
  TOPICS_MODAL_SUBMIT: "Topics Modal - Submit",
  UNSUBSCRIBE_TOGGLE_PREFERENCE: "Unsubscribe - Toggle Preference",
  UNSUBSCRIBE_SAVE_PREFERENCES: "Unsubscribe - Save Preferences",
  UNSUBSCRIBE_NOT_INTERESTED_MORTGAGE_TOGGLE:
    "Unsubscribe - Not Interested Mortgage toggle",
  UNSUBSCRIBE_SUBSCRIBE_TO_ALL: "Unsubscribe - Subscribe To All",
  UNSUBSCRIBE_UNSUBSCRIBE_FROM_ALL: "Unsubscribe - Unsubscribe From All",
  UNSUBSCRIBE_NOT_INTERESTED_MODAL_SAVE: "Unsubscribe - Not Interested Modal Save",
  VERIFY_MY_IDENTITY: "Verify my identity",
  VIEW_MORTGAGE: "View mortgage",
  VISIT_PRODUCT_SWITCH_PORTAL: "Visit product switch portal",
  WELCOME_LA_MODAL_GOT_IT: "Welcome LA modal - Got it",
  WHY_ARE_MY_PAYMENTS_CHANGING: "Why are my payments changing",
  WHY_ARENT_I_SEEING_ANY_LEADS: "Why aren't I seeing any leads",
  ONBOARDING__POLICIES__SAVE_AND_CLOSE: "Onboarding - policies - save and close",
  EXPIRY_DATE_MORTGAGE_COLLAPSED: "Expiry date mortgage collapsed",
  EXPIRY_DATE_MORTGAGE_EXPANDED: "Expiry date mortgage expanded",
  CURRENT_SITUATION_MORTGAGE_COLLAPSED: "Current Situation mortgage collapsed",
  CURRENT_SITUATION_MORTGAGE_EXPANDED: "Current Situation mortgage expanded",
  EXPIRY_DATE_BASED_ON_COLLAPSED: "Expiry date based on collapsed",
  EXPIRY_DATE_BASED_ON_EXPANDED: "Expiry date based on expanded",
  CURRENT_SITUATION_BASED_ON_COLLAPSED: "Current Situation based on collapsed",
  CURRENT_SITUATION_BASED_ON_EXPANDED: "Current Situation based on expanded",
  SUB_MENU_INFO_COOKIE_POLICY: "Sub menu - Info - Cookie policy",
  SUB_MENU_INFO_PRIVACY_POLICY: "Sub menu - Info - Privacy policy",
  SUB_MENU_TRAINING_VIDEOS: "Sub menu - training videos",
  SUB_MENU_KNOWLEDGE_BASE: "Sub menu - knowledge base",
  GENERAL_SETTINGS_SAVE: "General Settings - save",
  INFO_BUTTON_CHART: "Chart - Info button",
  INFO_BUTTON_CONSUMER_STATISTICS: "Consumer Statistics - Info button",
  LEFT_ARROW_PIE_CHART: "Pie chart - Left arrow",
  RIGHT_ARROW_PIE_CHART: "Pie chart - Right arrow",
  MARK_AS_BUTTON: "Consumer details - Mark as",
  PORTAL_MARK_AS_BUTTON: "Consumer details - Portal Mark as",
  REMIND_PRODUCTS_REQUEST: "Remind when products available - Request",
  WITHOUT_ADVICE_ACCEPTANCE_MODAL_UNDERSTAND:
    "Without advice acceptance modal - Understand button ",
  WITHOUT_ADVICE_ACCEPTANCE_MODAL_BACK:
    "Without advice acceptance modal - Back button ",
  INTERNAL_ADVICE_ACCEPTANCE_MODAL_BACK:
    "Internal advice acceptance modal - Back button ",
  EXTERNAL_ADVICE_ACCEPTANCE_MODAL_BACK:
    "External advice acceptance modal - Back button ",
  WITHOUT_ADVICE_ACCEPTANCE_MODAL_OPEN: "Without advice acceptance modal - Open",
  WITHOUT_ADVICE_ACCEPTANCE_MODAL_OPEN_CONTENT_BOX:
    "Without advice acceptance modal - Open - Content Box",
  INTERNAL_ADVICE_ACCEPTANCE_MODAL_OPEN: "Internal advice acceptance modal - Open",
  INTERNAL_ADVICE_ACCEPTANCE_MODAL_OPEN_CONTENT_BOX:
    "Internal advice acceptance modal - Open - Content Box",
  EXTERNAL_ADVICE_ACCEPTANCE_MODAL_OPEN: "External advice acceptance modal - Open",
  EXTERNAL_ADVICE_ACCEPTANCE_MODAL_OPEN_CONTENT_BOX:
    "External advice acceptance modal - Open - Content Box",
  PRODUCT_SELECTION_BACK_TO_MY_SITUATION: "Product Selection - Back to my situation",
  PRODUCT_SELECTION_EXECUTION_ONLY: "Product Selection - Explain Execution Only",
  PRODUCT_SELECTION_REPRESENTATIVE_EXAMPLE:
    "Product Selection - Representative Example",
  PRODUCT_SELECTION_TEMP_ALDERMORE: "Product Selection - Temp 2 Yr Discount",
  PRODUCT_SELECTION_MORE_YEARS: "Product Selection - More Years",
  PRODUCT_SELECTION_ERC: "Product Selection - ERC",
  PRODUCT_SELECTION_APRC: "Product Selection - APRC",
  PRODUCT_SELECTION_FEES: "Product Selection - Fees",
  PRODUCT_SELECTION_FOLLOW_ON_PAYMENT: "Product Selection - Follow On Payment",
  PRODUCT_SELECTION_LTV: "Product Selection - LTV",
} as const

type BasicTrackingId = typeof buttonTrackingIds[keyof typeof buttonTrackingIds]

type ModalAction = "close" | "open"
type ModalTrackingId = `${string} - Modal ${ModalAction}`
type ButtonWithLabel = `${string} - ${BasicTrackingId}`

export type ButtonTrackingId = BasicTrackingId | ModalTrackingId | ButtonWithLabel

export default buttonTrackingIds
