import { LONGTERM_KEY, SESSION_KEY } from "../redux/slices/persistantSession"

export const essential = {
  [LONGTERM_KEY]: LONGTERM_KEY,
  [SESSION_KEY]: SESSION_KEY,
  authToken: "token",
  cookieConsentKey: "cookieConsentKey",
  currentUserId: "currentUserId",
  directCallRequest: "directCallRequest",
  email: "email",
  emailForTwoFA: "2fa_email",
  hideApprovalSubmitMsg: "hideApprovalSubmitMsg",
  identToken: "identToken",
  isCreatingPassword: "isCreatingPassword",
  isVisitedLA: "isVisitedLA",
  mortgage: "mortgage",
  onboardingCompletedModalBeenShown: "onboardingCompletedModalBeenShown",
  onboardingWelcomeModalBeenShown: "onboardingWelcomeModalBeenShown",
  passwordForTwoFA: "2fa_new_password",
  phoneNumberRequired: "2fa_phone_number_required",
  resetToken: "resetToken",
  showContentId: "showContentId",
  userPermissionsWelcomeModalBeenShown: "userPermissionsWelcomeModalBeenShown",
}

export const functional = {
  articles: "portalArticles",
  blogFeedback: "blogFeedback",
  currentSituationBeenExpanded: "currentSituationBeenExpanded",
  propertyValueGraphBeenShown: "propertyValueGraphBeenShown",
  showContentId: "showContentId",
  upfrontPreferencesBeenShown: "upfrontPreferencesBeenShown",
}

export default {
  ...essential,
  ...functional,
}
