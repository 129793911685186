import { useEffect, useRef } from "react"
import { useLocation } from "react-router"
import { useRouteMatch } from "react-router-dom"

import routes from "../../constants/routes"

const ScrollToTop = ({ element }: { element: HTMLDivElement | HTMLElement | null }) => {
  const location = useLocation()

  const expiryPageMatch = useRouteMatch({
    path: [
      routes.CONSUMER.EXPIRY_DATE_PROPERTY_INDEX,
      routes.CONSUMER.PORTAL.CURRENT_SITUATION,
    ],
    exact: true,
  })

  const previousLocationPathName = useRef(location.pathname)
  const previousExpiryPageMatchPath = useRef(expiryPageMatch?.path)

  useEffect(() => {
    if (element && location.pathname !== previousLocationPathName.current) {
      previousLocationPathName.current = location.pathname
      if (
        !expiryPageMatch?.path ||
        expiryPageMatch.path !== previousExpiryPageMatchPath.current
      ) {
        previousExpiryPageMatchPath.current = expiryPageMatch?.path
        element.scrollTo(0, 0)
      }
    }
  }, [location.pathname, expiryPageMatch?.path, element])

  return null
}

export default ScrollToTop
