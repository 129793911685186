import { createSlice } from "@reduxjs/toolkit"

export interface TopicsState {
  postLogin: boolean
  aboveBlogs: boolean
  alreadyAnswered: boolean
}

const initialState = {
  postLogin: false,
  aboveBlogs: false,
  alreadyAnswered: false,
} as TopicsState

const topics = createSlice({
  name: "topics",
  initialState,
  reducers: {
    hideTopics(state) {
      state.postLogin = false
      state.aboveBlogs = false
      state.alreadyAnswered = false
    },
  },
})

export const { hideTopics } = topics.actions
export default topics.reducer
