import "normalize.css"
import "react-app-polyfill/stable"
import "array-flat-polyfill"
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only"

import runtimeEnv from "@mars/heroku-js-runtime-env"
import * as Sentry from "@sentry/react"
import ReactDOM from "react-dom"
import { HelmetProvider } from "react-helmet-async"
import { Provider as StoreProvider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import smoothscroll from "smoothscroll-polyfill"
import "trickling/lib/style.css"

import App from "./components/App"
import ErrorBoundary from "./components/common/ErrorBoundary"
import isEventWithSourceFile from "./lib/isEventWithSourceFile"
import { storageAvailable } from "./lib/localStorageUtil"
import store from "./redux"

const ORIGIN_FINGERPRINT = "missing-origin-header"
const CHUNK_FINGERPRINT = "chunk-load-error"

const env = runtimeEnv()

if (env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    environment: env.REACT_APP_ENVIRONMENT || "unknown",
    ignoreErrors: [
      // This is a rejection thrown by Outlook https://github.com/getsentry/sentry-javascript/issues/3440
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
      // Error thrown by iOS Edge https://github.com/getsentry/sentry-javascript/issues/8444
      "Can't find variable: msDiscoverChatAvailable",
      /**
       * React internal error thrown when something outside react modifies the DOM
       * This is usually because of a browser extension or Chrome's built-in translate
       */
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    ],
    denyUrls: [/^webkit-masked-url:\/\/hidden/i],
    beforeSend(event, hint) {
      if (isEventWithSourceFile(event)) {
        if (event.extra?.body.sourceFile.startsWith("chrome-extension")) {
          return null
        }
      }

      // Fingerprint events that we believe are from an email security scanning tool
      if (
        event.tags?.["response.status"] === 403 &&
        event.tags?.["403.reason"] === "Missing 'Origin' header"
      ) {
        if (env.REACT_APP_SENTRY_REPORTS?.includes(ORIGIN_FINGERPRINT)) {
          event.fingerprint = [ORIGIN_FINGERPRINT]
          return event
        }
        return null
      }

      const exception = hint.originalException
      if (
        exception &&
        exception instanceof Error &&
        exception.message.match(/Loading (CSS )?chunk \d{1,3} failed/)
      ) {
        if (env.REACT_APP_SENTRY_REPORTS?.includes(CHUNK_FINGERPRINT)) {
          event.fingerprint = [CHUNK_FINGERPRINT]
          return event
        }
        return null
      }

      return event
    },
  })

  Sentry.getCurrentScope().setTag(
    "localStorage.available",
    String(storageAvailable("localStorage"))
  )
  Sentry.getCurrentScope().setTag(
    "sessionStorage.available",
    String(storageAvailable("sessionStorage"))
  )
}

smoothscroll.polyfill()

const rootEl = document.getElementById("root")

if (rootEl && env.REACT_APP_DOWNTIME_MSG) {
  ReactDOM.render(
    <main className="downtime">
      <section>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
          />
        </svg>
        <p>This site is currently down for planned maintenance.</p>
        <p>{env.REACT_APP_DOWNTIME_MSG}</p>
      </section>
    </main>,
    rootEl
  )
} else if (rootEl) {
  ReactDOM.render(
    <BrowserRouter>
      <HelmetProvider>
        <StoreProvider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </StoreProvider>
      </HelmetProvider>
    </BrowserRouter>,
    rootEl
  )
} else {
  console.error("Unable to start. Perhaps the app was embedded on the wrong page")
}
