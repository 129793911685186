import ActionType from "../../../constants/actionTypes"
import { Topic, TopicStore } from "../../../types/retain/Topics.types"

export const initialState: TopicStore = {
  topics: [],
  isLoading: false,
  errorMessage: null,
}

type GetRequestAction = {
  type: ActionType.GET_TOPICS_REQUEST
}

type GetSuccessAction = {
  type: ActionType.GET_TOPICS_SUCCESS
  topics: Topic[]
}

type GetFailureAction = {
  type: ActionType.GET_TOPICS_FAILURE
  errorMessage: string
}

export type Action = GetRequestAction | GetSuccessAction | GetFailureAction

export default (state: TopicStore = initialState, action: Action): TopicStore => {
  switch (action.type) {
    case ActionType.GET_TOPICS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case ActionType.GET_TOPICS_SUCCESS:
      return {
        ...state,
        topics: action.topics,
        errorMessage: null,
        isLoading: false,
      }
    case ActionType.GET_TOPICS_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false,
      }
    default:
      return state
  }
}
