import { createApi } from "@reduxjs/toolkit/query/react"

import {
  BadDataValues,
  Meeting,
  Snooze,
} from "../../../components/business/ConsumerActionBar/ConsumerActionBar.types"
import { ActivitySummary } from "../../../components/business/UserActivities/userActivityTypes"
import { parseLead } from "../../../lib/parsers/parseLead"
import { PRETTY_CONSUMER_ID } from "../../../lib/reportAxiosError"
import { LockResponse } from "../../../types/lockTypes"
import { notInterestedCategories, PaginatedResponse } from "../../../types/retain"
import { Consumer, Lead, RawLead } from "../../../types/retain/Consumer.types"
import { ListsStore } from "../../../types/retain/List.types"
import axiosBaseQuery from "../axiosBaseQuery"
import transformActivitiesResponse from "./transformActivitiesResponse"
import transformListsResponse from "./transformListsResponse"

export const CONSUMER_TAG = "Consumer"
export const ACTIVITY_TAG = "Activity"
export const LISTS_TAG = "Lists"

export const consumerFactorApi = createApi({
  reducerPath: "consumerFactorApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: [CONSUMER_TAG, ACTIVITY_TAG, LISTS_TAG],
  endpoints: (builder) => ({
    getConsumer: builder.query<Lead, string>({
      query: (id) => ({
        url: `/consumer/${id}/`,
        reportContext: {
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/`,
          ignoreStatuses: [401],
        },
      }),
      providesTags: (result, error, id) => [{ type: CONSUMER_TAG, id }],
      transformResponse: (response: RawLead) => parseLead(response),
    }),
    getConsumers: builder.query<
      PaginatedResponse<Consumer>,
      {
        advisor: (string | null)[]
        offset?: number
        limit?: number
        q?: string
        query?: string
      }
    >({
      query: (params) => ({
        url: `/consumer/`,
        params,
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      providesTags: [CONSUMER_TAG],
      onQueryStarted: (_, api) => {
        api.dispatch(consumerFactorApi.util?.invalidateTags([LISTS_TAG]))
      },
    }),
    getLockStatus: builder.query<LockResponse, string>({
      query: (id) => ({
        url: `/consumer/${id}/lock/`,
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/lock/`,
        },
      }),
    }),
    lockConsumer: builder.mutation<LockResponse, { consumerId: string }>({
      query: ({ consumerId }) => ({
        url: `/consumer/${consumerId}/lock/`,
        method: "POST",
        reportContext: { ignoreStatuses: [401] },
      }),
      invalidatesTags: [CONSUMER_TAG],
    }),
    unlockConsumer: builder.mutation<LockResponse, { consumerId: string }>({
      query: ({ consumerId }) => ({
        url: `/consumer/${consumerId}/lock/`,
        method: "DELETE",
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/lock/`,
        },
      }),
      invalidatesTags: [CONSUMER_TAG],
    }),
    getActivities: builder.query<
      PaginatedResponse<ActivitySummary> | undefined | null,
      { id: string; offset?: number; limit?: number }
    >({
      query: ({ id }) => ({
        url: `/consumer/${id}/activity/`,
      }),
      providesTags: (result, error, { id }) => [{ type: ACTIVITY_TAG, id }],
      transformResponse: transformActivitiesResponse,
    }),
    postMeetingBooked: builder.mutation<
      Consumer,
      { consumerId: string; meeting: Meeting }
    >({
      query: ({ consumerId, meeting }) => ({
        url: `/consumer/${consumerId}/meeting/`,
        method: "POST",
        requestData: meeting,
        reportContext: {
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/meeting/`,
          // It's expected that a 403 might happen due to a lock etc
          ignoreStatuses: [403],
        },
      }),
      invalidatesTags: (result, error, { consumerId }) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: consumerId },
      ],
    }),
    postAssignAdvisor: builder.mutation<
      Consumer,
      { consumerId: string; newAdvisorId: string }
    >({
      query: ({ consumerId, newAdvisorId }) => ({
        url: `/consumer/${consumerId}/assign/`,
        method: "POST",
        requestData: {
          newAdvisorId,
        },
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/assign/`,
        },
      }),
      invalidatesTags: (result, error, { consumerId }) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: consumerId },
      ],
    }),
    markAsBadData: builder.mutation<
      {},
      { consumerId: string; requestData: BadDataValues }
    >({
      query: ({ consumerId, requestData }) => ({
        url: `/consumer/${consumerId}/bad-data/`,
        method: "POST",
        requestData,
        reportContext: {
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/bad-data/`,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: arg?.consumerId },
      ],
    }),
    snoozeConsumer: builder.mutation<{}, { consumerId: string; requestData: Snooze }>({
      query: ({ consumerId, requestData }) => ({
        url: `/consumer/${consumerId}/snooze/`,
        method: "POST",
        requestData,
      }),
      invalidatesTags: (result, error, arg) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: arg?.consumerId },
      ],
    }),
    resetConsumer: builder.mutation<Consumer, { consumerId: string }>({
      query: ({ consumerId }) => ({
        url: `/consumer/${consumerId}/reset/`,
        method: "POST",
        reportContext: {
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/reset/`,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: arg?.consumerId },
      ],
    }),
    postRetained: builder.mutation<
      Consumer,
      { consumerId: string; mortgageIds: string[] }
    >({
      query: ({ consumerId, mortgageIds }) => ({
        url: `/consumer/${consumerId}/retained/`,
        method: "POST",
        requestData: {
          mortgageIds,
        },
        reportContext: {
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/retained/`,
          // It's expected that a 403 might happen due to a lock etc
          ignoreStatuses: [403],
        },
      }),
      invalidatesTags: (result, error, { consumerId }) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: consumerId },
      ],
    }),
    notInterested: builder.mutation<
      Consumer,
      {
        consumerId: string
        mortgageIds: string[]
        category: notInterestedCategories
        reasonForOther?: string
      }
    >({
      query: ({ consumerId, mortgageIds, category, reasonForOther }) => ({
        url: `/consumer/${consumerId}/not-interested/`,
        method: "POST",
        requestData: {
          mortgageIds,
          category,
          reasonForOther,
        },
        reportContext: {
          prettyURL: `/api-v1/consumer/${PRETTY_CONSUMER_ID}/not-interested/`,
          // It's expected that a 403 might happen due to a lock etc
          ignoreStatuses: [403],
        },
      }),
      invalidatesTags: (result, error, { consumerId }) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: consumerId },
      ],
    }),
    listHide: builder.mutation<
      Consumer,
      {
        consumerId: string
      }
    >({
      query: ({ consumerId }) => ({
        url: `/consumer/${consumerId}/list-hide/`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { consumerId }) => [
        CONSUMER_TAG,
        LISTS_TAG,
        { type: ACTIVITY_TAG, id: consumerId },
      ],
    }),
    getLists: builder.query<ListsStore, void>({
      query: () => ({
        url: `/lists/`,
        reportContext: { ignoreStatuses: [401] },
      }),
      providesTags: [LISTS_TAG],
      transformResponse: transformListsResponse,
    }),
  }),
})

export const {
  useGetConsumerQuery,
  useGetConsumersQuery,
  useGetLockStatusQuery,
  useLazyGetLockStatusQuery,
  useGetActivitiesQuery,
  usePostMeetingBookedMutation,
  usePostAssignAdvisorMutation,
  useLockConsumerMutation,
  useUnlockConsumerMutation,
  useMarkAsBadDataMutation,
  useSnoozeConsumerMutation,
  useResetConsumerMutation,
  usePostRetainedMutation,
  useNotInterestedMutation,
  useListHideMutation,
  useGetListsQuery,
} = consumerFactorApi
