import { OnboardingResponseUserState } from "../../components/business/Onboarding/OnboardingResponseTypes"
import ActionType from "../../constants/actionTypes"
import { EmployeeUser } from "../../types/retain/Advisor.types"
import { Auth } from "../../types/retain/Auth.types"
import { Consumer } from "../../types/retain/Consumer.types"
import { Firm } from "../../types/retain/Firms.types"

export const initialState = {
  businessSide: undefined,
  isAuth: false,
  authRefreshLoading: false,
  isLoading: false,
  errorMessage: undefined,
  firstTimeUser: undefined,
  canRequestCall: undefined,
  onboarding: null,
  currentUser: undefined,
  currentUserId: undefined,
  previousFirstTimeUser: false,
  firm: undefined,
  availableLists: [],
  permissions: {
    "firm-wide-view": false,
    "view-employee-activity": false,
    "employee-settings": false,
    "firm-settings": false,
    "management-reports": false,
    "latest-expiry-first": false,
    "view-suppression-list": false,
    "upload-files": false,
    "download-files": false,
    "assign-orphans": false,
    "can-be-assigned": false,
    "can-mark-as": false,
    "see-any-list": false,
    "see-search": false,
    "see-call-list": false,
    "see-lifecycle-list": false,
    "see-urgent-list": false,
    "see-portal-list": false,
    approval: false,
    "approval-internal": false,
    "show-all-leads-filter": false,
    "portal-can-mark-as": false,
    "comms-opted-out-mortgage": false,
    "view-invalid-email-list": false,
  },
  featureFlags: [],
  askAboutTopics: {
    postLogin: false,
    aboveBlogs: false,
    alreadyAnswered: false,
  },
}

type RequestAction = {
  type: ActionType.LOGIN_REQUEST | ActionType.USER_REQUEST | ActionType.LOGOUT_REQUEST
}

type UserSuccessAction = {
  type: ActionType.LOGIN_SUCCESS | ActionType.USER_SUCCESS
  isAuth: boolean
  firm: Firm
  businessSide: boolean
  currentUser: Consumer | EmployeeUser
  currentUserId: string
  firstTimeUser?: boolean
  canRequestCall?: boolean
  home: string
  permissions: Auth["permissions"]
  availableLists: Auth["availableLists"]
  featureFlags: string[]
  askAboutTopics: {
    postLogin: boolean
    aboveBlogs: boolean
    alreadyAnswered: boolean
  }
  onboarding?: OnboardingResponseUserState | null
}

type LogoutSuccessAction = {
  type: ActionType.LOGOUT_SUCCESS
}

type FailureAction = {
  type: ActionType.LOGIN_FAILURE | ActionType.USER_FAILURE | ActionType.LOGOUT_FAILURE
  errorMessage: string
}

type HideTopicsAction = {
  type: ActionType.HIDE_TOPICS
}

type OnboardingAction = {
  type: ActionType.SET_BRANDING_OPTIMISTIC
  isColorFinished: boolean
  isLogoFinished: boolean
}

export type Action =
  | RequestAction
  | UserSuccessAction
  | LogoutSuccessAction
  | FailureAction
  | HideTopicsAction
  | OnboardingAction

export default function (state: Auth = initialState, action: Action): Auth {
  const previousFirstTimeUser = state.currentUser?.hasCreatedAccount === false

  switch (action.type) {
    case ActionType.LOGIN_REQUEST:
    case ActionType.USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }
    case ActionType.LOGIN_SUCCESS:
    case ActionType.USER_SUCCESS:
      return {
        ...state,
        isAuth: action.isAuth,
        isLoading: false,
        errorMessage: undefined,
        firstTimeUser: action.firstTimeUser,
        canRequestCall: action.canRequestCall,
        businessSide: action.businessSide,
        currentUser: action.currentUser,
        currentUserId: action.currentUserId,
        firm: action.firm,
        home: action.home,
        permissions: action.permissions || state.permissions,
        availableLists: action.availableLists || [],
        featureFlags: action.featureFlags ?? state.featureFlags,
        askAboutTopics: action.askAboutTopics ?? state.askAboutTopics,
        onboarding: action.onboarding || null,
      }
    case ActionType.LOGIN_FAILURE:
    case ActionType.USER_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false,
      }

    case ActionType.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case ActionType.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        firstTimeUser: undefined,
        canRequestCall: undefined,
        currentUser: undefined,
        currentUserId: undefined,
        previousFirstTimeUser,
      }
    case ActionType.LOGOUT_FAILURE:
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        firstTimeUser: undefined,
        canRequestCall: undefined,
        currentUser: undefined,
        currentUserId: undefined,
        previousFirstTimeUser,
        errorMessage: action.errorMessage,
      }
    case ActionType.HIDE_TOPICS:
      return {
        ...state,
        askAboutTopics: {
          postLogin: false,
          aboveBlogs: false,
          alreadyAnswered: false,
        },
      }
    case ActionType.SET_BRANDING_OPTIMISTIC: {
      const { isLogoFinished, isColorFinished } = action
      const isFinished = isColorFinished && isLogoFinished
      const isOneOfThemFinished = isColorFinished || isLogoFinished

      return {
        ...state,
        onboarding: {
          progressPct: isFinished ? 20 : isOneOfThemFinished ? 10 : 0,
          totalSteps: 5,
          doneSteps: isFinished ? 1 : 0,
          status: [
            ...(state.onboarding?.status || []),
            {
              module: "branding",
              status: isFinished ? "completed" : "in-progress",
              steps: [
                { label: "Brand Colours", done: isColorFinished },
                { label: "Upload Logos", done: isLogoFinished },
              ],
            },
          ],
        },
      }
    }
    default:
      return state
  }
}
