import { FieldProps } from "formik"
import { RouteComponentProps } from "react-router"

import { LoadApprovalState } from "../components/business/LoadApproval/LoadApproval.types"
import { HoveredItem } from "../components/common/Chart"
import { UpdateStore } from "../redux/reducers/business/consumerUpdate"
import { ImageEditorState } from "../redux/slices/imageEditor"
import { OnboardingState } from "../redux/slices/onboarding"
import { ProductSelectionState } from "../redux/slices/productSelection"
import { TopicsState } from "../redux/slices/topics"
import { UserInterfaceState } from "../redux/slices/ui"
import { Auth } from "./retain/Auth.types"
import { BusinessConsumerState } from "./retain/Consumer.types"
import { CurrentSituationStore } from "./retain/CurrentSituation.types"
import { PermissionGroupsStore } from "./retain/Permission.types"
import { TopicStore } from "./retain/Topics.types"

export const notInterestedCategories = {
  __OTHER_DEPRECATED: "other", // Deprecated
  PAID_OFF_MORTGAGE: "paid-off-mortgage",
  REMO_DIRECT: "remortgaged-directly",
  SOLD_PROPERTY: "sold-property",
  USED_NEW_BROKER: "used-new-broker",
  USED_ADVISOR: "used-advisor",
  DIFFERENT_LENDER: "different-lender",
  NOT_SWITCHING: "not-switching",
  UNSPECIFIED_FROM_EMAIL: "unspecified-from-email",
  PREFER_NOT_TO_SAY: "prefer-not-to-say",
} as const

export type notInterestedCategories =
  typeof notInterestedCategories[keyof typeof notInterestedCategories]

/** for more information about the way we split these up see https://www.notion.so/eligible/Dynamic-content-CTA-s-Lenders-576a2cbd01714a0ca595fc848186b511 */

export interface RouteState {
  redirectTo?: Omit<RouteComponentProps["location"], "state" | "hash">
}

export interface CtaPromptStore {
  isLoading: boolean
  errorMessage?: string
  dismissed: boolean
  settings?: {
    showCtaPromptConsumer: boolean
    showCtaPromptMobile: boolean
    showCtaPromptTablet: boolean
    showCtaPromptDesktop: boolean
    ctaPromptText: string
    ctaPromptDelay: number
  }
}

export type Store = {
  auth: Auth
  route: RouteState
  permissionGroups: PermissionGroupsStore
  businessConsumer: BusinessConsumerState
  currentSituation: CurrentSituationStore
  ctaPrompt: CtaPromptStore
  charts: ChartStore
  topics: TopicStore
  consumerUpdate: UpdateStore
  ui: UserInterfaceState
  loadApproval: LoadApprovalState
  onboarding: OnboardingState
  askAboutTopics: TopicsState
  productSelection: ProductSelectionState
  imageEditor: ImageEditorState
}

export type FetchHook<T> = {
  data?: T
  loading: boolean
  error: boolean
  setData: React.Dispatch<React.SetStateAction<T | undefined>>
}

export type PaginatedResponse<T> = {
  results: T[]
  count: number
  previous?: string
  next?: string
}

export const feedbackTypes = {
  ERROR: "error",
  REVIEW: "review",
  CLASSIFICATION: "classification",
  MESSAGE: "message",
  ACTION: "action",
  EXPIRED: "expired",
} as const

export type feedbackTypes = typeof feedbackTypes[keyof typeof feedbackTypes]

export interface InputProps extends FieldProps {
  id?: string
  describedBy?: string
  labelledBy?: string
  inputClass?: string
}

export interface ChartStore {
  [chartId: string]: {
    hoveredItem: HoveredItem | null
    sliderIdx: number
  }
}

export type RadioOption<T = string> = Record<
  string,
  {
    value: T
    label: string
    display: string
    withMessage?: boolean
  }
>

/**
 * Form related types
 */
export interface FormValues {
  email?: string
  newPassword?: string
  passwordConfirmation?: string
  currentPassword?: string
}

export const HeadingLevel = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  P: "p",
} as const

export type HeadingLevel = typeof HeadingLevel[keyof typeof HeadingLevel]
