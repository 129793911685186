export const topicsInfoBoxBlocks = {
  topicsInfoBoxAnsweredButtonLabel: {
    type: "text",
    description: "Label for the button in the answered state of the topics info box",
    default: "Update",
  },
  topicsInfoBoxAnsweredCopy: {
    type: "text",
    description: "Copy for the answered state of the topics info box",
    default:
      "If your situation has changed, you can update your interest preferences here",
  },
  topicsInfoBoxAnsweredTitle: {
    type: "text",
    description: "Title for the answered state of the topics info box",
    default: "Update Interests",
  },
  topicsInfoBoxButtonLabel: {
    type: "text",
    description: "Label for the button in the default state of the topics info box",
    default: "Start",
  },
  topicsInfoBoxCopy: {
    type: "text",
    description: "Copy for the default state of the topics info box",
    default:
      "By telling us which topics interest you, we will show you content that suits you",
  },
  topicsInfoBoxTitle: {
    type: "text",
    description: "Title for the default state of the topics info box",
    default: "Get personalised content",
  },
  topicsModalTitle: {
    type: "text",
    description: "Title for the topics modal",
    default: "What would interest you?",
  },
} as const
