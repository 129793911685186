import localStorageKeys, { functional } from "../constants/localStorageKeys"
import logSentryBreadcrumb from "./logSentryBreadcrumb"

export function storageAvailable(type: "localStorage" | "sessionStorage") {
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  let storage
  try {
    storage = window[type]
    const keyValue = "__storage_test__"
    storage.setItem(keyValue, keyValue)
    storage.removeItem(keyValue)
    return true
  } catch (e) {
    const available = !!(
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
    if (!available) {
      logSentryBreadcrumb({
        message: `${type} was not available`,
        type: "error",
        category: type,
        data: {
          errorCode: e instanceof DOMException && e.code,
          errorName: e instanceof DOMException && e.name,
          errorMessage: e instanceof DOMException && e.message,
          storageLength: storage && storage.length !== 0,
        },
      })
    }
    return available
  }
}

export function getLocalStorageItem(k: string): string | null {
  let value: string | null = null
  try {
    value = localStorage.getItem(k)
    logSentryBreadcrumb({
      message: `localStorage.getItem(${k}) ${
        value !== null ? "retrieved" : "did not retrieve anything"
      }`,
      type: "info",
      category: "localStorage",
    })
    return value
  } catch (err) {
    logSentryBreadcrumb({
      message: `localStorage.getItem(${k}) failed: ${err}`,
      type: "info",
      level: "error",
      category: "localStorage",
    })
    return null
  }
}

export function setLocalStorageItem(k: string, v: string) {
  try {
    const consent: null | { functional: Record<string, string> } = JSON.parse(
      getLocalStorageItem(localStorageKeys.cookieConsentKey) ?? "{}"
    )

    if (!consent?.functional && Object.values(functional).includes(k)) {
      logSentryBreadcrumb({
        message: `Consent not given for localStorage item ${k})`,
        type: "info",
        level: "warning",
        category: "localStorage",
      })
      return
    }

    localStorage.setItem(k, v)
    logSentryBreadcrumb({
      message: `localStorage.setItem(${k})`,
      type: "info",
      category: "localStorage",
    })
  } catch (err) {
    logSentryBreadcrumb({
      message: `setLocalStorageItem(${k}, ${v}) failed: ${err}`,
      type: "info",
      level: "error",
      category: "localStorage",
    })
  }
}

export function removeLocalStorageItem(k: string) {
  try {
    localStorage.removeItem(k)
    logSentryBreadcrumb({
      message: `localStorage.removeItem(${k})`,
      type: "info",
      category: "localStorage",
    })
  } catch (err) {
    logSentryBreadcrumb({
      message: `localStorage.removeItem(${k}) failed: ${err}`,
      type: "info",
      level: "error",
      category: "localStorage",
    })
  }
}

export const removeLocalStorageItems = (keys: string[]) =>
  keys.forEach((k) => removeLocalStorageItem(k))

export function clearLocalStorage() {
  try {
    localStorage.clear()
    logSentryBreadcrumb({
      message: `localStorage.clear()`,
      type: "info",
      category: "localStorage",
    })
  } catch (err) {
    logSentryBreadcrumb({
      message: `localStorage.clear() failed: ${err}`,
      type: "info",
      level: "error",
      category: "localStorage",
    })
  }
}

export function getSessionStorageItem(k: string): string | null {
  let value: string | null = null
  try {
    value = sessionStorage.getItem(k)
    logSentryBreadcrumb({
      message: `sessionStorage.getItem(${k}) ${
        value !== null ? "retrieved" : "did not retrieve anything"
      }`,
      type: "info",
      category: "sessionStorage",
    })
    return value
  } catch (err) {
    logSentryBreadcrumb({
      message: `sessionStorage.getItem(${k}) failed: ${err}`,
      type: "info",
      level: "error",
      category: "sessionStorage",
    })
    return null
  }
}

export function setSessionStorageItem(k: string, v: string) {
  try {
    sessionStorage.setItem(k, v)
    logSentryBreadcrumb({
      message: `sessionStorage.setItem(${k})`,
      type: "info",
      category: "sessionStorage",
    })
  } catch (err) {
    logSentryBreadcrumb({
      message: `sessionStorage.setItem(${k}) failed: ${err}`,
      type: "info",
      level: "error",
      category: "sessionStorage",
    })
  }
}

export function clearSessionStorage() {
  try {
    sessionStorage.clear()
    logSentryBreadcrumb({
      message: `sessionStorage.clear()`,
      type: "info",
      category: "sessionStorage",
    })
  } catch (err) {
    logSentryBreadcrumb({
      message: `sessionStorage.clear() failed: ${err}`,
      type: "info",
      level: "error",
      category: "sessionStorage",
    })
  }
}

export function removeSessionStorageItem(k: string) {
  try {
    sessionStorage.removeItem(k)
    logSentryBreadcrumb({
      message: `sessionStorage.removeItem(${k})`,
      type: "info",
      category: "sessionStorage",
    })
  } catch (err) {
    logSentryBreadcrumb({
      message: `sessionStorage.removeItem(${k}) failed: ${err}`,
      type: "info",
      level: "error",
      category: "sessionStorage",
    })
  }
}
