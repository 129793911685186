import {
  useFloating,
  useInteractions,
  useClick,
  useRole,
  useDismiss,
  FloatingPortal,
  FloatingOverlay,
  FloatingFocusManager,
} from "@floating-ui/react-dom-interactions"
import cx from "classnames"
import { ReactNode } from "react"

import { ReactComponent as Close } from "../../../../images/Close.svg"
import { ButtonLink } from "../../../common"
import ClickableDiv from "../../../common/ClickableDiv/ClickableDiv"
import s from "./Modal.module.scss"

type ModalProps = {
  children: ReactNode
  open: boolean
  handleClose: () => void
  preventEscClose?: boolean
  modalClass?: string
  noFade?: boolean
  hideClose?: boolean
}

export default function Modal({
  children,
  open,
  handleClose,
  preventEscClose,
  modalClass,
  noFade,
  hideClose = false,
}: ModalProps) {
  const { floating, context } = useFloating({ open })

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      escapeKey: !preventEscClose,
    }),
  ])

  return (
    <FloatingPortal>
      {open && (
        <FloatingOverlay
          onClick={handleClose}
          className={cx(s.overlay, { [s.noFade]: noFade })}
          lockScroll
        >
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: floating,
                className: cx(s.modal, modalClass),
              })}
            >
              {!hideClose && (
                <ButtonLink
                  label="Close modal"
                  className={s.closeButton}
                  onClick={handleClose}
                >
                  <span>Close</span> <Close className={s.closeIcon} />
                </ButtonLink>
              )}

              <ClickableDiv
                className={s.content}
                onClick={(e) => {
                  e?.stopPropagation()
                }}
              >
                {children}
              </ClickableDiv>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  )
}
